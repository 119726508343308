window.addEventListener('load', function() {
    /* Store the element in el */
    let elements = document.querySelectorAll('.tilt')

    elements.forEach(function(el) {

        /*
        * Add a listener for mousemove event
        * Which will trigger function 'handleMove'
        * On mousemove
        */
        el.addEventListener('mousemove', handleMove)

        /* Get the height and width of the element */
        const height = el.clientHeight
        const width = el.clientWidth

        /* Define function a */
        function handleMove(e) {
            /*
            * Get position of mouse cursor
            * With respect to the element
            * On mouseover
            */
            /* Store the x position */
            const xVal = e.layerX
            /* Store the y position */
            const yVal = e.layerY

            /*
            * Calculate rotation value along the Y-axis
            * Here the multiplier 20 is to
            * Control the rotation
            * You can change the value and see the results
            */
            const yRotation = 20 * ((xVal - width / 2) / width)

            /* Calculate the rotation along the X-axis */
            const xRotation = -20 * ((yVal - height / 2) / height)

            /* Generate string for CSS transform property */
            const string = 'perspective(500px) scale(1.1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'

            /* Apply the calculated transformation */
            el.style.transform = string;

			/* Update z-index so that it appears on top of other blocks */
			el.style.zIndex = 1000000;

        }

        /* Add listener for mouseout event, remove the rotation */
        el.addEventListener('mouseleave', function() {
            el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)';
			el.style.zIndex = 0;
        })

        /* Add listener for mousedown event, to simulate click */
        el.addEventListener('mousedown', function() {
            el.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)';
        })

        /* Add listener for mouseup, simulate release of mouse click */
        el.addEventListener('mouseup', function() {
            el.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)';
        })
    });




    (function() {
        window.addEventListener('resize', function() {
            let width = window.visualViewport.width;
            if (width < 768) {
                let svg = document.querySelector('#polygon_field svg');
                svg.pauseAnimations();
            }
        });
    })();
});
