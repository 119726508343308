initMap = function() {
    var target = {lat: 39.8579472, lng: -75.5421148};
    var map = new google.maps.Map(
        document.getElementById('map'), {zoom: 14, center: target}
    );
    var marker = new google.maps.Marker({
        position: target,
        map: map
    });

    infowindow = new google.maps.InfoWindow({
        content: "391 Wilmington Pike, #3-417, Glen Mills, PA 19342"
    });

    infowindow.open(map, marker);
}
