(function() {
    let inline_list = document.querySelectorAll('.inline_list_item');
    if (inline_list) {
        inline_list.forEach(function(list_item) {
            list_item.addEventListener('click', function(event) {
                inline_list_toggle(this);
            });
        });
    }

    var inline_list_toggle = function(item) {
        inline_list = document.querySelector('.inline_list');
        inline_list.classList.add('qt_no_display');
        document.querySelectorAll('.description_container').forEach(function(item) {
            item.classList.add('qt_no_display');
        });

        var description_container = document.querySelector('#' + item.id + '_description');
        primary_services = document.querySelector('#primary_services');
        top_nav = document.querySelector('nav.fixed-top');

        window.scrollTo({
            top: (description_container.offsetTop - top_nav.offsetHeight),
            behavior: 'smooth'
        });
        // apply animation effects

        // description_container.style.transform = "translate(-1%)";
        // description_container.style.opacity = "0";
        // description_container.style.WebkitTransition = "transform 1.5s ease-in-out, opacity 1.7s";
        // description_container.style.MozTransition = "transform 1.5s ease-in-out, opacity 1.7s";

        document.querySelector('.description_container').classList.remove('qt_no_display');
        document.querySelector('.inline_list_back_button').classList.remove('qt_no_display');
        description_container.classList.remove('qt_no_display');
        //
        description_container.style.transform = "none";
        // description_container.style.opacity = "1";
    }

    let inline_list_back_button = document.querySelector('.inline_list_back_button');
    if (inline_list_back_button) {
        inline_list_back_button.addEventListener('click', function () {
            document.querySelector('.inline_list').classList.remove('qt_no_display');
            document.querySelectorAll('.inline_description').forEach(function (description) {
                description.classList.add('qt_no_display');
                if (description.style.transform === "none") description.style.transform = "";
            });
            this.classList.add('qt_no_display');
            primary_services = document.querySelector('#primary_services');
            top_nav = document.querySelector('nav.fixed-top');

            window.scrollTo({
                top: (primary_services.offsetTop - top_nav.offsetHeight),
                behavior: 'smooth'
            });
        });
    }

    // var learn_more_buttons = document.querySelectorAll('.inline_list_learn_more');
    // learn_more_buttons.forEach(function(button) {
    //     button.addEventListener('click', function() {
    //         primary_services = document.querySelector('#primary_services');
    //         top_nav = document.querySelector('nav.fixed-top');
    //
    //         window.scrollTo({
    //             top: (primary_services.offsetTop - top_nav.offsetHeight),
    //             behavior: 'smooth'
    //         });
    //
    //         debugger;
    //     });
    // });

    double_down_arrow = document.querySelector('.double_down_arrow');
    if (double_down_arrow) {
        double_down_arrow.addEventListener('click', function() {
            let triple_list = document.querySelector('#triple_list');
            top_nav = document.querySelector('nav.fixed-top');
            window.scrollTo({
                top: (triple_list.offsetTop - top_nav.offsetHeight),
                behavior: 'smooth'
            });
        });
    }

    // contact form labels
    inputs = document.querySelectorAll('.contact_form_input');
    occupied_class = 'contact_form_occupied';

    inputs.forEach(function(input) {
        label = document.querySelector('label[for="' + input.name + '"]');


        label.addEventListener('click', function() {
            input.focus();
        });

        // using SEAFs here to bypass closure pulling variables from the wrong
        // context

        (function(input, label) {
            input.addEventListener('focus', function() {
                has_occupied_class = label.classList.contains(occupied_class);
                if (!has_occupied_class) {
                    label.classList.add(occupied_class);
                }
            });
        })(input, label);


        (function(input, label) {
            input.addEventListener('blur', function() {
                zero_length = (this.value.length === 0);
                if (zero_length) {
                    label.classList.remove(occupied_class);
                }
            });
        })(input, label);

        (function(input, label) {
            input.addEventListener('input', function() {
                zero_length = input.value.length === 0;
                has_occupied_class = label.classList.contains(occupied_class);
                if (!has_occupied_class && !zero_length) {
                    label.classList.add(occupied_class);
                }
            });
        })(input, label);


        (function(input, label) {
            input.addEventListener('animationstart', function(event) {
                if (event.animationName === 'onAutoFillStart') {
                    has_occupied_class = label.classList.contains(occupied_class);
                    if (!has_occupied_class) {
                        label.classList.add(occupied_class);
                    }
                } else if (event.animationName === 'onAutoFillCancel') {
                    zero_length = (this.value.length === 0);
                    if (zero_length) {
                        label.classList.remove(occupied_class);
                    }
                }
            });
        })(input, label);

    });
})();

(function() {
    window.addEventListener('pageshow', function() {
        form_inputs = document.querySelectorAll('.contact_form_input');
        form_inputs.forEach(function(input) {
            event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });
            input.dispatchEvent(event);
        });
    });
})();



(function() {
    message_textarea = document.querySelector('textarea.contact_form_input[name="message"]');
    if (!message_textarea) return;
    message_textarea.addEventListener('input', function() {
        if (message_textarea.value.length === 0) {
            message_textarea.setCustomValidity('Please fill out this field.');
        } else {
            message_textarea.setCustomValidity('');
        }
    });
})();
