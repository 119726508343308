// function inView (el) {
//   var sb = section.getBoundingClientRect();
//   var eb = el.getBoundingClientRect();
//   return !((eb.top + eb.height < 0) || (eb.top > sb.height));
// }
//
// function updateInView() {
//   for (x of document.querySelectorAll('div')) {
//     if (inView(x)) x.classList.add('inview')
//     else x.classList.remove('inview');
//   }
// }
//
// section.onscroll = updateInView;
//
// updateInView();



let ParallaxUp = {
  init(el, displace)  {
    this.animateItem(el, displace);
  },
  setPosition() {
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset;
    } else {
      return (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
  },
  animateItem(el, displace) {
    let width = window.visualViewport.width;
    let height = window.visualViewport.height;
    let small = displace[0];
    let medium = displace[1];
    let large = displace[2];
    let xlarge = displace[2]
    let size = small;
    let scrollPosition = this.setPosition();

    // if (width < 768) {
    //     position = 0;
    // } else if (width >= 768 && width < 960) {
    //     position = (scrollPosition / medium);
    // } else if (width >= 960 && width < 1200) {
    //     position = (scrollPosition / large);
    // } else {
    //     position = (scrollPosition / xlarge);
    // }
    var position = scrollPosition / (height / 1000);

    el.style.transform = "translate3d(0px, "+ (position) +"px, 0px)";
  }
}

animated_element = document.querySelector('.parallax_up');

let handleScrolling = function() {
    if (animated_element) {
        ParallaxUp.init(animated_element, [0, -4.3, -2, 1]);
    }
};


window.addEventListener('scroll', handleScrolling);
handleScrolling();




// Prevent unknown scrolling states
window.onunload = function(){ window.scrollTo(0,0); }
